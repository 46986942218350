<template>
  <div class="shopMain">
    <div class="c h sb sep-t padding-05" @click="drawer.shop = true">
      <div class="no-flex padding-05">自定义区域</div>
      <div class="flex ta-c"></div>
      <div>
        <span>{{ store.shopTitle }}</span>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
    </div>
    <div class="c h sb sep-t">
      <div class="no-flex padding-10">时间范围</div>
      <div class="c h">
        <div class="h c" key="range-date">
          <el-date-picker v-model="query.begDate" size="mini" type="date" value-format="timestamp" placeholder="选择开始日" :clearable="true" :editable="false" style="width: 2.4rem" />
          <div class="padding-0-05">至</div>
          <el-date-picker v-model="query.endDate" size="mini" type="date" value-format="timestamp" placeholder="选择结束日" :clearable="true" :editable="false" style="width: 2.4rem" />
        </div>
      </div>
    </div>
    <!-- 选直营门店 -->
    <shop-list :visible.sync="drawer.shop" v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="handleShopChange" @init="handleInited" />
  </div>
</template>

<script>
import shopList from "./shopList";
export default {
  props: {
    showOpt: Object,
    query: Object,
  },
  components: {
    shopList,
  },
  data() {
    return {
      loading: false,
      label: {
        brand: "全部",
        series: "全部",
        category: "全部",
        categoryB: "全部",
        goods: "全部",
      },
      goods: {
        brandName: null,
        seriesName: null,
        categoryName: null,
        categoryAllname: null,
      },
      drawer: {
        shop: false,
      },
      store: {
        shopTitle: "全部",
      },
    };
  },
  methods: {
    handleInited() {
      this.$emit("init");
    },
    handleShopChange(data) {
      this.store.shopTitle = data ? data.name : "全部";
    },
  },
};
</script>